import { GetStaticProps } from "next";
import { NextSeo } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { PageDataFetchError, withRetryGraphQL502 } from "@looksrare/utils";
import { HomeView } from "@/views";
import { getCanonicalAndLanguageAlternates, getChainInfo } from "@/utils";
import { Page } from "@/components/Layout";
import { contentfulLocale } from "@/config/localization";
import { YG_CONTENTFUL_GAME_CARDS_ID, YG_CONTENTFUL_HERO_SLIDER_ID } from "@/config/constants";
import { ContentfulHeroSlide, ContentfulHomeGameCard } from "@/types/contentful";
import { getHeroSlider } from "./api/contentful/heroSlider";
import { getHomeGameCards } from "./api/contentful/homeGameCards";

interface HomeProps {
  heroSlides: ContentfulHeroSlide[];
  homeGameCards: ContentfulHomeGameCard[];
}

const Home = ({ heroSlides, homeGameCards }: HomeProps) => {
  const router = useRouter();
  const currentChainInfo = getChainInfo();
  const { canonical, languageAlternates } = getCanonicalAndLanguageAlternates("/", router, "https://yologames.io");
  const title = "YOLO Games | The Home of Degen Gaming";
  const description = "Degen games. Massive payouts. Seasonal rewards. Play and earn on YOLO Games now!";

  return (
    <Page title={title}>
      <NextSeo
        title={title}
        openGraph={{
          url: canonical,
          title,
          description,
          images: [
            {
              url: `${currentChainInfo.appUrl}/images/season-2.png`,
              alt: "YoloGames",
              width: 1600,
              height: 840,
              type: "image/png",
            },
          ],
        }}
        canonical={canonical}
        languageAlternates={languageAlternates}
      />
      <HomeView heroSlides={heroSlides} homeGameCards={homeGameCards} />
    </Page>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) =>
  withRetryGraphQL502(async () => {
    try {
      const contentLocale = contentfulLocale[locale] || "en";

      const [{ heroSliderCollection }, { homeGameCardsCollection }] = await Promise.all([
        getHeroSlider(YG_CONTENTFUL_HERO_SLIDER_ID, contentLocale),
        getHomeGameCards(YG_CONTENTFUL_GAME_CARDS_ID, contentLocale),
      ]);

      return {
        props: {
          heroSlides: heroSliderCollection.items?.[0]?.slidesCollection.items ?? [],
          homeGameCards: homeGameCardsCollection.items?.[0]?.cardsCollection.items ?? [],
          ...(await serverSideTranslations(locale, ["common"])),
          locale,
        },
        /**
         * @note - The page's cache times are managed via `CDN-` & `Vercel-CDN-` cache-control headers in next.config.js
         */
        revalidate: 60,
      };
    } catch (e) {
      throw new PageDataFetchError("/", e);
    }
  });

export default Home;
